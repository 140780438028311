import React, { useCallback, useMemo } from 'react';

import Dropzone from 'react-dropzone';
import FilePreview from './file-preview';
import { getFileObject } from 'helpers/FileHelpers';
import { DropzoneContainer, DropzoneText } from './components';
import { ChallengeApplet } from 'models/ChallengeApplet';
import { Applet } from 'models/Applet';
import localization from 'localization';

export type UploadFile = {
  name: string;
  type: string;
  uri: string;
  mime?: string;
};

export enum FileType {
  images = 'image/*',
  videos = 'video/mp4,video/quicktime',
  docs = 'application/pdf,text/plain',
  audio = 'audio/*',
  all = 'image/*,video/mp4,video/quicktime,application/pdf,text/plain,audio/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/rtf,application/vnd.oasis.opendocument.text',
}

type AppUploadProps = {
  value?: UploadFile | Applet | ChallengeApplet;
  onChangeFile?: (value: UploadFile | undefined) => void;
  disabled?: boolean;
  previewPointerEvents?: React.CSSProperties['pointerEvents'];
  fileType?: FileType[] | FileType;
};

const AppUpload = ({
  value,
  onChangeFile = () => {},
  disabled,
  previewPointerEvents,
  fileType = FileType.all,
}: AppUploadProps) => {
  const removeFile = useCallback(() => {
    onChangeFile(undefined);
  }, [onChangeFile]);

  const safeValue = useMemo(() => getFileObject(value), [value]);

  const acceptedFileTypes = useMemo(
    () => (fileType instanceof Array ? fileType.join(',') : fileType),
    [fileType],
  );

  const handleFileDrop = useCallback(
    (files) => {
      if (files.length > 0) {
        const file = getFileObject({
          name: files[0].name,
          type: files[0].type,
          uri: URL.createObjectURL(files[0]),
        });
        onChangeFile(file);
      } else {
        alert(localization.formatErrorMessage);
      }
    },
    [onChangeFile],
  );

  return (
    <>
      {!safeValue ? (
        <Dropzone
          data-testid="drag-drop-zone"
          maxFiles={1}
          multiple={false}
          onDrop={handleFileDrop}
          accept={acceptedFileTypes}>
          {({ getRootProps, getInputProps }) => (
            <DropzoneContainer
              {...getRootProps({ 'data-testid': 'drag-drop-zone' })}>
              <input data-testid="drag-drop-zone-input" {...getInputProps()} />
              <DropzoneText>Drop file here or click to upload</DropzoneText>
            </DropzoneContainer>
          )}
        </Dropzone>
      ) : (
        <FilePreview
          file={safeValue}
          removeFile={removeFile}
          disabled={disabled}
          style={{ pointerEvents: previewPointerEvents }}
        />
      )}
    </>
  );
};

export default AppUpload;
